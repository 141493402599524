import { useState, useEffect } from "react";
import { Modal } from "../../components/Modal";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { ValePresente } from "../../components/ValePresente";

import { Error } from "../../components/ValePresente/error";
import { Encerrada } from "../../components/ValePresente/encerrada";
import { ExpiradoPersonalite } from "../../components/ValePresente/expiradoPersonnalite";
import { Expirado } from "../../components/ValePresente/expirado";
import { Footer } from "../../components/Footer";
import { Bloqueado } from "../../components/ValePresente/bloqueado";

import { ValePresenteCineMarckCombo } from "../../components/ValePresenteCineMarckCombo";
import { MultiplosVoucher } from "../../components/MultiplosVoucher";
import { CinemarkPrivatePardeIngresso } from "../../components/MultiplosVoucher/cinemarkPrivatePardeIngresso";
import { MultiplosVoucherGrandCru } from "../../components/MultiplosVoucher/grandCru";
import { PersonaliteCodigo } from "../../components/MultiplosVoucher/personaliteCodigo";
import { PersonaliteCodigoSemValor } from "../../components/MultiplosVoucher/personaliteCodigoSemValor";
import { Schweppesintense } from "../../components/MultiplosVoucher/schweppesintense";
import { UniclassSemValor } from "../../components/MultiplosVoucher/uniclassSemValor";
import { UniclassCodigo } from "../../components/MultiplosVoucher/uniclassCodigo";
import { GrandCruPrivate } from "../../components/MultiplosVoucher/grandCruPrivate";

import { PersonnaliteLink } from "../../components/MultiplosVoucher/uniclassLink ";
import { MvGrandGruItauShop } from "../../components/MultiplosVoucher/MvGrandGruItauShop";
import { LinkSemValor } from "../../components/MultiplosVoucher/linkSemValor";
import { UniclassLink } from "../../components/MultiplosVoucher/personnaliteLink";
import { Stone } from "../../components/MultiplosVoucher/Stone";

import { Loader } from "../../components/Loader";

import { ModalTutorial } from "../../components/PassoAPasso/modal";
import { useHistory } from "react-router-dom";
import { formatDateUser } from "../../util/helper";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";
import { Container } from "./styles";
import { PersonaliteCodigoCopoStanley } from "../../components/MultiplosVoucher/personaliteCodigoCopoStanley";
import { toast } from "react-toastify";
import { MultiplosValePresente } from "../../components/MultiplosValePresente";
import { PontosItauUniclass } from "../../components/MultiplosVoucher/PontosItauUniclass";

interface Item {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    expires_at: string | null | any;
    foto_capa: string | null;
    foto_capa_secundario: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    template?: string | null;
    informacoes_importantes: string;
    detalhes: string;
    codes?: any;
    template_voucher_id: number;
    variacao?: any;
    external_link: string;
    external_link_label: string;
}
export interface ResponseVoucher {
    success: boolean;
    error: string;
    produto_id?: number;
    status: string;
    voucher?: Item[];
    codes?: any;
    expires_at?: string | null | any;
    template_voucher_id?: any;
}

export function PageSandBox() {
    const history = useHistory();
    const { setHashCode } = useVoucherConfig();
    const [voucher, setVoucher] = useState<ResponseVoucher>(
        {} as ResponseVoucher
    );
    const [codes, setCodes] = useState<string[][]>([]);
    // const [templateId, setTemplateId] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const personalite = [2, 4, 5, 6, 8];
    const hash_code =
        history?.location?.pathname?.replace("/s/", "") ||
        window?.location?.pathname?.replace("/s/", "") ||
        "token não encontrado";

    const templaId = voucher?.template_voucher_id
        ? voucher.template_voucher_id
        : 0;

    const configProduto = {
        title: "Produtos",
        video: voucher?.voucher ? voucher?.voucher[0]?.tutorial_url : "",
        tutorial: voucher?.voucher
            ? voucher?.voucher[0]?.tutorial_passos
            : "[]",
        img: voucher?.voucher ? voucher?.voucher[0]?.foto_capa : "",
        tutorialObservacao: voucher?.voucher
            ? voucher?.voucher[0]?.tutorial_observacao
            : "",
        template_id: voucher?.voucher
            ? voucher?.voucher[0]?.template_voucher_id
            : null,
        onClose: () => {
            setModalTutorial(false);
            setModalVideo(false);
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
        dados: voucher?.voucher?.length ? voucher?.voucher[0] : {},
    };

    const configVideo = {
        video: voucher?.voucher ? voucher?.voucher[0]?.tutorial_url : "",
        modalVideo: modalVideo,
        closeModal: () => {
            setModalTutorial(true);
            setModalVideo(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModalTutorial(false);
            setModalVideo(false);
        }
    }

    function renderTemplates() {
        if (loading) {
            return (
                <div className="loader">
                    <Loader />
                </div>
            );
        }
        if (voucher?.voucher) {
            switch (voucher?.voucher[0].template_voucher_id) {
                case 1:
                    return (
                        <ValePresente
                            voucher={
                                voucher.voucher ? voucher.voucher[0] : undefined
                            }
                            loading={loading}
                            setModalTutorial={setModalTutorial}
                        />
                    );
                case 2:
                    return (
                        <MultiplosVoucher
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                        />
                    );
                case 3:
                    return (
                        <ValePresenteCineMarckCombo
                            voucher={
                                voucher.voucher ? voucher.voucher[0] : undefined
                            }
                            codes={codes}
                            loading={loading}
                            setModalTutorial={setModalTutorial}
                            sandbox={true}
                        />
                    );
                case 4:
                    return (
                        <MultiplosVoucherGrandCru
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                        />
                    );
                case 5:
                    return (
                        <PersonaliteCodigo
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 6:
                    return (
                        <UniclassCodigo
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 7:
                    return (
                        <PersonaliteCodigoSemValor
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 8:
                    return (
                        <UniclassSemValor
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 9:
                    return (
                        <CinemarkPrivatePardeIngresso
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                        />
                    );
                case 10:
                    return (
                        <GrandCruPrivate
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 11:
                    return (
                        <Schweppesintense
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 12:
                    return (
                        <PersonnaliteLink
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 13:
                    return (
                        <UniclassLink
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 14:
                    return (
                        <LinkSemValor
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 15:
                    return (
                        <PersonaliteCodigoCopoStanley
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 16:
                    return (
                        <MvGrandGruItauShop
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 17:
                    return (
                        <Stone
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );
                case 18:
                    return (
                        <MultiplosValePresente
                            voucher={
                                voucher.voucher ? voucher.voucher[0] : undefined
                            }
                            loading={loading}
                            setModalTutorial={setModalTutorial}
                        />
                    );
                case 19:
                    return (
                        <PontosItauUniclass
                            setModalTutorial={setModalTutorial}
                            voucher={voucher}
                            loading={loading}
                            name={voucher?.voucher[0].nome || ""}
                        />
                    );

                default:
                    return <></>;
            }
        }
    }

    useEffect(() => {
        async function checkToken() {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_HTTP}/pedido/check-url`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            hash_code,
                        }),
                    }
                );
                const dados: ResponseVoucher = await response.json();

                setCodes(dados.codes);
                setHashCode(hash_code);
                setVoucher(dados);
            } catch (error: any) {
                toast.error(
                    "Algo de errado aconteceu no carrergamento do token"
                );
                console.log(error);
            }
            setLoading(false);
        }

        if (hash_code === "token não encontrado") {
            alert(`Token não encontrado ${JSON.stringify(window.location)}`);
            return;
        } else {
            checkToken();
        }
    }, [hash_code, history, setHashCode]);

    return (
        <Container>
            {voucher.success === false ? (
                <>
                    {voucher?.status === "BLOQUEADO" && <Bloqueado />}
                    {voucher?.status === "ESTORNADO" && <Encerrada />}
                    {voucher?.error === "Link not found" && <Error />}
                    {(voucher?.status === "EXPIRADO" ||
                        voucher?.error === "Link expirado") &&
                    personalite.includes(
                        voucher?.template_voucher_id as number
                    ) ? (
                        <ExpiradoPersonalite
                            templateId={templaId}
                            date={formatDateUser(voucher?.expires_at)}
                        />
                    ) : voucher?.status === "EXPIRADO" ||
                      voucher?.error === "Link expirado" ? (
                        <Expirado date={formatDateUser(voucher?.expires_at)} />
                    ) : null}

                    <Footer />
                </>
            ) : (
                renderTemplates()
            )}

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />
            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </Container>
    );
}
